.loader_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    /* Задаем высоту экрана для вертикального центрирования */
}

.loader {
    width: 50px;
    aspect-ratio: 1;
    --c: no-repeat radial-gradient(farthest-side, #514b82 92%, transparent);
    background:
        var(--c) 50% 0,
        var(--c) 50% 100%,
        var(--c) 100% 50%,
        var(--c) 0 50%;
    background-size: 10px 10px;
    animation: l18 1s infinite;
    position: relative;
}

.loader::before {
    content: "";
    position: absolute;
    inset: 0;
    margin: 3px;
    background: repeating-conic-gradient(transparent 0 35deg, #514b82 0 90deg);
    mask: radial-gradient(farthest-side, transparent calc(100% - 3px), #000 0);
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - 3px), #000 0);
    border-radius: 50%;
}

@keyframes l18 {
    100% {
        transform: rotate(0.5turn);
    }
}