.detailProduct {
    width: 80%;
    margin: 30px auto;
}

.detailContainer {
    display: flex;
    justify-content: space-between;
    width: 100%;
    gap: 15px;
}

.updateProductForm {
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 25px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 400px;
}

.formGroup {
    margin-bottom: 20px;
}

.formGroup label {
    display: block;
    margin-bottom: 8px;
    font-weight: bold;
    color: #333;
}

.formGroup input {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
    font-size: 16px;
}

.formGroup input:focus {
    border-color: #525bc4;
    outline: none;
}

.submitButton {
    background-color: #525bc4;
    color: white;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.submitButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.submitButton_barcode {
    background-color: #257c1d;
    color: white;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-top: 5px;
    width: 50%;
}

.submitButton_barcode:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.submitButtons {
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 5px;
}

.transaction_showcase_names_border {
    min-width: 1045px;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
}

.transaction_showcase_names {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 10px;
}

.transactionsContainer {
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 25px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    min-width: 1100px;
    overflow: scroll;
    height: 908px;
}

.transaction_showcase_apiData {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #e7ded7;
    border-radius: 5px;
    margin-bottom: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.transaction_showcase_apiData:hover {
    transform: scale(1.02);
}

.transaction_name,
.transaction_person_name,
.transaction_quantity,
.transaction_price,
.transaction_amount {
    padding: 5px;
    text-align: center;
}

.transaction_name {
    width: 140px;
}

.transaction_person_name {
    width: 159px;
}

.transaction_quantity {
    width: 128px;
}

.transaction_price {
    width: 109px;
}

.transaction_amount {
    width: 85px;
}

.transactions_border {
    margin-top: 10px;
}

.updateProductFormH2 {
    text-align: center;
    margin-bottom: 30px;
}

.transaction_thename_face {
    position: relative;
    left: 74px;
}

.transaction_thename_quantity {
    position: relative;
    left: 106px;
}

.transaction_thename_data {
    font-weight: bold;
    color: #525bc4;
    cursor: pointer;
}

.transaction_thename_price {
    position: relative;
    left: 96px;
}

.transaction_thename_amount {
    position: relative;
    left: 91px;
}

.transaction_type_selector {
    position: relative;
    left: 25px;
    bottom: 10px;
    padding: 5px 5px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid #525bc4;
    background-color: #525bc4;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transaction_type_selector:hover {
    background-color: #525bc4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.transaction_type_selector:focus {
    outline: none;
    box-shadow: 0 0 8px #525bc4;
}

.submitButton_delete {
    background-color: #c52e2e;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.submitButton_delete:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.closeModalButton {
    background-color: #8a8686;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.closeModalButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 230px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.model_delete_product_h1 {
    text-align: center;
    padding: 15px 20px;
    border: 2px solid #b0b0b0;
    background-color: #b0b0b0;
    border-radius: 8px;
}

.model_delete_product_text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.dateFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.dateFilterContainer label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.dateFilterContainer input[type="date"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 100%;
}

.dateFilterContainer input[type="date"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    outline: none;
}

.applyButton {
    background-color: #525bc4;
    color: white;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.applyButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.transaction_showcase_names_name {
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
    color: #333;
}

.barcodeWrapper {
    width: 346px;
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.barcode {
    max-width: 100%;
    max-height: 100%;
}

.loadingMessage {
    color: #888;
    font-style: italic;
}

.generate_barcode {
    display: grid;
    grid-template-columns: 80% 20%;
}

.toggle_switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    margin-left: 10px;
    margin-top: 9px;
}

.toggle_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 25px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4CAF50;
}

input:checked + .slider:before {
    transform: translateX(24px);
}

@media screen and (max-width: 1900px) {
    .detailProduct {
        width: 95%;
        margin: 30px;
    }

    .transactionsContainer {
        min-width: 73%;
    }

    .transaction_showcase_names_border {
        min-width: 100%;
    }
}

@media screen and (max-width: 1400px) {
    .updateProductForm {
        min-width: 28%;
    }

    .barcodeWrapper {
        width: 100%;
    }

    .transaction_showcase_apiData {
        width: 900px;
        overflow: auto;
    }

    .transaction_showcase_names_border {
        width: 900px;
        overflow: auto;
    }
}

@media screen and (max-width: 1050px) {
    .detailContainer {
        display: grid;
        width: 95%;
        gap: 15px;
        margin: 0 auto;
    }
    .updateProductForm {
        min-width: 28%;
        width: 80%;
        margin: 0 auto;
    }
    .detailProduct {
        margin: 30px auto;
    }
}