.Main_Sale {
    display: flex;
    justify-content: center;
    align-items: center;
    height: max-content;
    transform: none;
    height: 100%;
}

.SalePage_Container {
    position: relative;
    background-color: #fff;
    height: 105%;
    width: 100%;
}
.sale_header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px auto 20px auto;
    width: 95%;
}
.sale__title {
    font-size: 30px;
    text-align: center;
    font-weight: bold;
}
.exit {
    background: transparent;
    border: none;
    font-size: 36px;
    color: #e13f3f;
    font-weight: bold;
    margin: 10px;
}
.sale__formFill {
    width: 85%;
    display: grid;
    grid-template-columns: 90% 10%;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    margin: 0 auto;
    padding: 30px 20px;
    border: 2px solid #ccc;
    border-radius: 12px;
    color: #333;
}

.sale__form {
    display: grid;
    gap: 16px;
    grid-template-columns: 2fr 1fr 1fr;
    width: 98%;
    text-align: center;
}

.sale__block {
    display: grid;
    width: 100%;
    padding: 0 0 0 50px;
    align-items: end;
}

.sale__block label {
    width: 80%;
    display: flex;
}
.searchInput {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
    margin: 10px 0 0 0;   
}
.int {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
    margin: 10px 0 0 0;
}


.inT {
    width: 90%;
    height: 40px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
}

.inT:focus {
    outline: none;
    /* Remove default outline */
    border-color: #007bff;
    /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Add shadow */
}
.sale__choice {
    display: flex;
    width: 100%;
    position: relative;
}
.select {
    position: absolute;
    top: 100%; /* Показывать список сразу под полем ввода */
    left: 0;
    width: 90%; /* Устанавливаем ширину на 100% от родителя */
    z-index: 10; /* Устанавливаем z-index, чтобы список отображался поверх других элементов */
    background-color: white; /* Цвет фона для списка */
    border: 1px solid #ccc; /* Граница для списка */
    max-height: 200px; /* Ограничение по высоте */
    overflow-y: auto; /* Добавляем прокрутку, если элементов много */
  }

.select:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.select option {
    padding: 15px;
    /* Padding for options */
    font-size: 16px;
    /* Font size for options */
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.select option:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
        0 6px 20px rgba(82, 91, 196, 0.1);
}

.option_warning  {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
        0 6px 20px rgba(82, 91, 196, 0.1);
}
.sale__btn {
    height: 40px;
    width: 130px;
    background-color: #525bc4;
    font-weight: bold;
    border-radius: 5px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    margin: 67px 0px 0px -25px;
    transition: transform 0.2s ease;
}

.sale__btn:active {
    transform: scale(0.90);
}
.coming__btn {
    height: 40px;
    width: 80%;
    background-color: #525bc4;
    font-weight: bold;
    border-radius: 5px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    padding: 10px;
    margin: 10px 0 0 0;
    transition: transform 0.2s ease;
}
.coming__btn:active {
    transform: scale(0.90);
}
.products_sale_names {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 10px;
    align-items: center;
    width: 86%;
    height: auto;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
    margin: 30px auto 20px auto;
}
.codeInt {
    width: 50%;
    border: none;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
    margin: 10px;
}
.codeInt:focus {
    outline: none;
    /* Remove default outline */
    border-color: #007bff;
    /* Change border color on focus */
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    /* Add shadow */
}
.btnSub {
    position: absolute;
    left: 91%;
    height: 30px;
    width: 90px;
    background-color: #525bc4;
    font-weight: bold;
    border-radius: 5px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    transition: transform 0.2s ease;
}

.btnSub:active {
    transform: scale(0.90);
}
.barcode_result {
    display: grid;
}
.barcode_label {
    width: 60%;
    margin: 0 auto;
}
.result_data {
    display: flex;
    text-align: center;
    gap: 20px;
}
.product__list_container {
    width: 85%;
    border: 2px solid #525bc4;
    border-radius: 5px;
    overflow: auto;
    min-height: 400px;
    max-height: 400px;
    padding: 20px 0;
    margin: 20px auto 10px auto;
}

.product_item {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    align-items: center;
    width: 95%;
    margin: 10px auto;
    border: 1px solid #e7ded7;
    border-radius: 10px;
    padding: 15px 30px;
    overflow: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.product_item:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
        0 6px 20px rgba(82, 91, 196, 0.1);
}

.closeBtn {
    position: absolute;
    left: 97%;
    font-size: 22px;
    color: #333;
    transition: color 0.1s ease;
    margin: 5px;
}

.closeBtn:hover {
    color: #e90303;
}

.product__int {
    width: 80%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
    margin: 10px 0 0 0;
}

.readOnly {
    background: #e9ecef;
}

.modalOverlay {
    position: fixed;
    top: 0px;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    position: relative;
    top: 3%;
    background-color: white;
    border-radius: 8px;
    padding: 20px 30px;
    margin-bottom: 60px;
    width: 650px;
    height: 80%;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
    overflow: auto;
}
.form {
    margin: 20px 0;
}
.Spend__subtitle {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 26px;
}
.from_control{
    display: grid;
    gap: 5px;
}
.choices {
    display: grid;
    gap: 5px;
}
.label {
    display: block;
    margin: 10px 0;
    font-weight: bold;
}
.customSelect {
    gap: 20px;
    width: 100%;
    max-height: 200px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
    background-color: #fff;
    color: #333;
    cursor: pointer;
    overflow-y: auto; /* Включаем скролл, если список превышает max-height */
    outline: none;
}
.customSelect:focus {
    outline: none;
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}
.customSelect option {
    padding: 10px;
    font-size: 16px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.customSelect option:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
        0 6px 20px rgba(82, 91, 196, 0.1);
}

.from_control input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    font-size: 16px;
}
.calculation {
    border-bottom: 1px solid #cfcfcf;
    padding: 0 0 20px 0;
}
.btns {
    width: 90%;
    display: flex;
    margin: 20px 0 0 0;
    border-top: 1px solid #cfcfcf;
    padding: 20px 0 0 0;
    gap: 30px;
}
.modalBtn {
    color: white;
    font-weight: bold;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
    padding: 0 30px;
}
.modalBtn:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}
.closeModal {
    background-color: #dc3545;
}   
.pushBtn {
    background-color: #525bc4;
}
.clearIcon {
    position: absolute;
    left: 84%;
    top: 9px;
    font-size: 20px;
    color: #525bc4;
}
.price_arrival {
    position: relative;
    text-align: start;
}
.toggle_prices_btn {
    position: absolute;
    border: none;
    padding: 10px;
    font-size: 20px;
    width: 40px;
    height: 40px;
    margin: 10px 0 0 5px;
    border-radius: 4px;
    background: #e9ecef;
} 
.selectedValue {
    padding:10px;
    margin: 5px 0 0 0;
}
.customSelectContainer {
    position: relative;
}
.clientList  {
    display: grid;
    gap: 5px;
    width: 100%;
    margin: 0 auto;
}
.clientItem {
    list-style: none;
    padding: 10px;
    margin: 10px 0 0 0;
    background: #f1f1f1;
}
.noClientItem {
    padding: 10px;
    margin: 10px 0 0 0;
    background: #f1f1f1;
}

@media screen and (max-width: 1500px) {
    .modalContent {
        width: 600px;
        height: 90%;
    }
}   

@media screen and (max-width: 1300px) {
    .sale__formFill {
        width: 92%;
    }
    .products_sale_names {
        width: 92%;
    }
    .product__list_container {
        width: 92%;
    }
    .sale__block {
        padding: 0 0 0 20px;
    }
    .SalePage_Container {
        height: 110%;
    }
    .modalContent {
        width: 550px;
        height: 80%;
    }
}
