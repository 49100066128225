.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
    height: 310px; 
    overflow: hidden; 
    margin-bottom: 300px;
}

.model_delete_product_h1 {
    text-align: center;
    padding: 15px 20px;
    border: 2px solid #b0b0b0;
    background-color: #b0b0b0;
    border-radius: 8px;
}

.model_delete_product_text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.closeModalButton {
    background-color: #8a8686; 
    color: white;
    font-weight: bold;
    padding: 12px 20px; 
    border: initial; 
    border-radius: 4px; 
    cursor: pointer; 
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.closeModalButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.submitButton_delete {
    background-color: #c52e2e; 
    color: white;
    font-weight: bold;
    padding: 14px 20px; 
    border: initial; 
    border-radius: 4px; 
    cursor: pointer; 
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.submitButton_delete:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.container_products {
    display: flex;
    margin-top: 10px;
    border: 1px solid #e7ded7;
    border-radius: 10px;
    padding: 20px 20px;
    overflow: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    background-color: #fff;
}

.container_products:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
                0 6px 20px rgba(82, 91, 196, 0.1);
}

.container_product {
    width: 150px;
}

.transactionContainer {
    max-height: 300px;
    overflow-y: auto; 
    margin-bottom: 20px; 
}

.btn_container {
    margin-top: 14px;
}

.container_product_person {
    margin-right: 40px;
}