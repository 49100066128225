* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

a {
  text-decoration: none;
}

@media print {
  body * {
      visibility: hidden;
  }
  #printable, #printable * {
      visibility: visible;
  }
  #printable {
      position: absolute;
      top: 0;
      left: 0;
  }
}
