.person_block {
    display: flex;
    justify-content: center;
    width: 100%;
}

.person_container{
    width: 90%;
}

.persons_showcase_names_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%; 
    height: auto;
    padding: 15px 30px;    
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    font-family: 'Arial', sans-serif; 
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.persons_showcase_names{
    display: grid;
    grid-template-columns: 40% 30% 30%;
    align-items: center;
    width: 88%;
}

.person_title {
    text-align: center;
    margin-top: 20px;
    font-size: 35px;
}

.person_list {
    height: 500px;
    overflow: auto;

}

.persons_number {
    font-weight: bold;
}

.persons_showcase_data {
    width: 95%;
    margin: 20px auto;
    border: 2px solid #525bc4;
    border-radius: 8px;
    padding: 20px 40px;
}

.person_report {
    display: flex;
    justify-content: space-between;
    width: 50%;
    height: 40px;
}

.person_report_border {
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    font-family: 'Arial', sans-serif; 
    font-weight: bold;
    color: #333;
    padding: 15px 15px;
}

.btn_person_create {
    height: 40px;
    width: 109px;
    background-color: #525bc4;
    border-radius: 5px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease; 
}

.btn_person_create:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.total_data {
    margin-top: 12px;
}

.person_search {
    display: flex;
    justify-content: space-between;
    width: 40%;
    height: 35px;
    margin-top: 10px;
}

.search_input {
    width: 400px;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s;
}

.search_input:focus {
    border-color: #8b7373;
    outline: none;
    box-shadow: 0 0 5px #525bc4;
}

.person_search_btn {
    height: 40px;
    width: 109px;
    background-color: #525bc4;
    border-radius: 5px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease; 
}

.person_search_btn:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.serach_label {
    margin-top: 9px;
    position: relative;
    right: 10px;
}

.search_checkbox {
    border-radius: 10px;
}


/* Modal window */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 60px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.formGroup {
    margin-bottom: 15px;
}

.create_person_label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.create_person_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
    font-size: 16px; 
}

.create_person_input::placeholder {
    color: #999; 
}

.create_person_input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.create_person_input:hover {
    border-color: #007bff;
}

.submitButton {
    background-color: #525bc4;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-top: 15px;
}

.submitButton:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.closeModalButton {
    background-color: #dc3545;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #dc354580;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    position: relative;
    font-weight: bold;
    bottom: 40px;
    left: 170px;
}

.closeModalButton:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #dc354580;;
}

.title_person_create_form {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.person_list_container {
    width: 100%;
    border: 1px solid rgb(231, 222, 215);
    border-radius: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    margin-top: 10px;
}
.persons_showcase_apiData {
    width: 88%;
    display: grid;
    grid-template-columns: 40% 30% 30%;
    padding: 20px 20px ;
}

.person_list_container:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
                0 6px 20px rgba(82, 91, 196, 0.1);
}

@media screen and (max-width: 1720px) {
    .person_report {
        width: 55%;
    }
    .person_search {
        width: 47%;
    }
    
}

@media screen and (max-width: 1670px) {
    .person_report {
        width: 55%;
    }
    .person_search {
        width: 47%;
    }
    
}

@media screen and (max-width: 1416px) {
    .person_report {
        width: 60%;
    }
    .person_search {
        width: 53%;
    }
    
}

@media screen and (max-width: 1335px) {
    .person_container {
        width: 100%;
    }
    .person_report {
        width: 65%;
    }
    .person_search {
        width: 53%;
    }
}

@media screen and (max-width: 1220px) {
    .person_container {
        width: 100%;
    }
    .person_report {
        width: 65%;
    }
    .person_search {
        width: 55%;
    }
}

@media screen and (max-width: 1100px) {
    .persons_showcase_data {
        padding: 20px 20px;
    }
    .person_report {
        width: 75%;
    }
    .person_search {
        width: 60%;
    }
    .modalContent {
        margin-bottom: 0px;
        width: 580px;
    }
}