.border_container {
    margin-top: 20px;
    border: 2px solid #525bc4;
    border-radius: 8px;
    padding: 20px 40px;
    width: 97%;
    margin-left: 29px;
}

.dailyReport_h1 {
    text-align: center;
    margin-top: 20px;
}

.names {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 99%;
    min-width: 1340px;
    height: auto;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.api_border {
    margin: 10px auto;
    border: 1px solid #e7ded7;
    border-radius: 10px;
    padding: 20px 20px;
    overflow: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    
}

.api_border:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
                0 6px 20px rgba(82, 91, 196, 0.1);
}

.fields_api {
    height: 600px;
    overflow: auto;
}

.api {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.fields_names {
    width: 100px;
}

.fields_names_data {
    color: #525bc4;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 230px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.dateFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.dateFilterContainer label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.dateFilterContainer input[type="date"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 100%;
}

.dateFilterContainer input[type="date"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    outline: none;
}

.applyButton {
    background-color: #525bc4; 
    color: white;
    padding: 12px 20px; 
    border: initial; 
    border-radius: 4px; 
    cursor: pointer; 
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;  
}

.applyButton:active   {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}
