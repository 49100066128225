.person_block {
    display: flex;
    justify-content: center;    
}

.persons_showcase_names {
    display: grid;
    grid-template-columns: 33.5% 33.5% 40%;
    height: auto;
    padding: 15px 30px;    
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    
    font-family: 'Arial', sans-serif; 
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.person_title {
    text-align: center;
    margin-top: 30px;
    font-size: 35px;
}


.person_list {
    height: 500px;
    overflow: auto;

}

.persons_name {
    width: 447px;
}

.persons_number {
    width: 462px;
    font-weight: bold;
}


.persons_debt {
    width: 400px;
}


.persons_showcase_data {
    margin-top: 20px;
    border: 2px solid #525bc4;
    border-radius: 8px;
    padding: 20px 40px;
}


.person_report_border {
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    font-family: 'Arial', sans-serif; 
    font-weight: bold;
    color: #333;
    padding: 15px 15px;
}

.btn_person_create {
    height: 40px;
    width: 109px;
    background-color: #525bc4;
    border-radius: 5px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease; 
}

.btn_person_create:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.person_search {
    display: flex;
    justify-content: space-between;
    width: 50%;
    height: 35px;
    margin: 10px 0 0 0 ;
}

.search_input {
    width: 400px;
    padding: 10px;
    border: 2px solid #ccc; 
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s;
}

.search_input:focus {
    border-color: #8b7373; 
    outline: none; 
    box-shadow: 0 0 5px #525bc4;
}

.person_search_btn {
    height: 40px;
    width: 109px;
    background-color: #525bc4;
    border-radius: 5px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease; 
}

.person_search_btn:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.serach_label {
    margin-top: 9px;
    position: relative;
    right: 10px;
}

.search_checkbox {
    border-radius: 10px;
}



/* Modal window */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 60px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.formGroup {
    margin-bottom: 15px;
}

.create_person_label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.create_person_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
    font-size: 16px; 
}

.create_person_input::placeholder {
    color: #999; 
}

.create_person_input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.create_person_input:hover {
    border-color: #007bff;
}


.create_password_input {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
    font-size: 16px; 
}

.create_password_input::placeholder {
    color: #999; 
}

.create_password_input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.create_password_input:hover {
    border-color: #007bff;
}

.submitButton {
    background-color: #525bc4;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-top: 15px;
}

.submitButton:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.closeModalButton {
    background-color: #dc3545;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #dc354580;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    position: relative;
    font-weight: bold;
    bottom: 40px;
    left: 170px;
}

.closeModalButton:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #dc354580;;
}

.title_person_create_form {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.persons_showcase_apiData {
    display: flex;
    margin-top: 10px;
    border: 1px solid rgb(231, 222, 215);
    border-radius: 10px;
    padding: 20px 20px ;
    overflow: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.persons_showcase_apiData:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
                0 6px 20px rgba(82, 91, 196, 0.1);
}

.toggle_switch {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 25px;
    margin-left: 10px;
}

.toggle_switch input {
    opacity: 0;
    width: 0;
    height: 0;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    transition: 0.4s;
    border-radius: 25px;
}

.slider:before {
    position: absolute;
    content: "";
    height: 17px;
    width: 17px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    transition: 0.4s;
    border-radius: 50%;
}

input:checked + .slider {
    background-color: #4CAF50;
}

input:checked + .slider:before {
    transform: translateX(24px);
}


.delete_button {
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 13px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
    margin-bottom: 2px;
}

.delete_button:hover {
    background-color: #d32f2f;
    transform: scale(1.05);
}

.delete_button:active {
    background-color: #b71c1c;
    transform: scale(0.95);
}

.delete_icon {
    font-size: 1.2rem;
}

.update_div{
    display: grid;
    grid-template-columns: 1fr 1fr;
    gap: 20px;
} 

.submitButton_delete {
    background-color: #c52e2e;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.submitButton_delete:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}


.closeModalButton_delete {
    background-color: #8a8686;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.closeModalButton_delete:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.model_delete_product {
    margin-top: 20px;
}

.model_delete_product_text {
    margin-top: 10px;
}

.toggle_prices_btn {
    position: absolute;
    border: none;
    padding: 10px;
    font-size: 20px;
    width: 40px;
    height: 40px;
    margin: 0 0 0 5px;
    border-radius: 4px;
    background: #e9ecef;
} 