.name_page {
    text-align: center;
    margin-top: 10px;
} 

.container {
    margin-top: 20px;
    border: 2px solid #525bc4;
    border-radius: 8px;
    padding: 20px 40px;
    margin-top: 25px;
    width: 1700px;
    margin-left: 93px;
}


.financeContainer {
    display: flex;
    margin-top: 10px;
    border: 1px solid #e7ded7;
    border-radius: 10px;
    padding: 20px 20px;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
    width: 49%;
}

.financeContainer:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
                0 6px 20px rgba(82, 91, 196, 0.1);
}

.finance_data {
    width: 40%;
    font-size: 18px;
    font-weight: bold;
    color: #333;
    margin-left: 130px;
}


.container_cash_result {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 1340px;
    height: auto;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.cash_result {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 22%;
}

.finance_data_result {
    font-size: 20px;
    font-weight: bold;
    color: #333;
}

.large_space {
    margin-left: 20px;
}


.container_data_cash {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}

.submitButton {
    background-color: #525bc4;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-top: 15px;
}

.submitButton:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 60px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.formGroup {
    margin-bottom: 15px;
}


.title_product_create_form {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.create_product_label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.closeModalButton {
    background-color: #dc3545;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #dc354580;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    position: relative;
    font-weight: bold;
    bottom: 40px;
    left: 170px;
}

.closeModalButton:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #dc354580;;
}

.create_product_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
    font-size: 16px; 
}

.create_product_input::placeholder {
    color: #999; 
}

.create_product_input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.create_product_input:hover {
    border-color: #007bff;
}

.navigator {
    border: 2px solid #525bc4;
    border-radius: 8px;
    padding: 20px 40px;
    width: 300px;
    transition: all 0.3s ease;
    text-align: center;
    font-size: 20px;
    margin-top: 30px;
}

.navigator:hover {
    background-color: #f0f0f0;
    border-color: #3a3f8e; 
    transform: scale(1.05);
}

.navigators {
    display: grid;
    grid-template-columns:1fr 1fr 1fr 1fr;
    margin: 0 auto;
    width: 89%;
    margin-top: 20px;
}