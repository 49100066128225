.detailContainer {
    display: grid;
    grid-template-columns: 1fr 1fr ;
    gap: 20px;
    width: 82%;
    margin: 30px auto;
}

.transaction_list {
    display: grid;
}
.updateProductForm {
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 25px; 
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    min-width: 400px;
    height: 690px;
}

.formGroup {
    margin-bottom: 20px; 
}

.formGroup label {
    display: block; 
    margin-bottom: 8px; 
    font-weight: bold; 
    color: #333; 
}

.formGroup input {
    width: 100%;
    padding: 12px; 
    border: 1px solid #ccc; 
    border-radius: 4px; 
    transition: border-color 0.3s; 
    font-size: 16px; 
}

.formGroup input:focus {
    border-color: #525bc4; 
    outline: none; 
}

.submitButton {
    background-color: #525bc4; 
    color: white;
    padding: 12px 20px; 
    border: initial; 
    border-radius: 4px; 
    cursor: pointer; 
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;  
}

.submitButton:active   {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.transaction_showcase_names_border {
    min-width: 1045px;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
}

.transaction_showcase_names {
    display: flex;
    justify-content: space-between;
    position: relative;
    top: 10px;
}

.transactionsContainer {
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 25px; 
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); 
    min-width: 1100px;
    overflow: scroll;
    height: 610px;
}

.transaction_showcase_apiData {
    display: flex;
    justify-content: space-between;    
    padding: 10px; 
    border: 1px solid #e7ded7;
    border-radius: 5px; 
    margin-bottom: 10px; 
    background-color: #ffffff; 
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); 
    transition: transform 0.2s; 
    width: 101%;
}

.transaction_showcase_apiData:hover {
    transform: scale(1.02); 
}

.transaction_name {
    width: 140px;
    padding: 5px; 
}

.transaction_transaction_type_display {
    margin-right: 265px;
    width: 60px;
}

.transaction_quantity {
    width: 128px;
    padding: 5px; 
    margin-left: 45px;
}

.transaction_thename_amount {
    margin-left: 50px;
}

.transactions_border {
    margin-top: 10px;
}

.updateProductFormH2 {
    text-align: center;
    margin-bottom: 30px;
}

.transaction_thename_face {
    position: relative;
    left: 74px;
}

.transaction_thename_data {
    font-weight: bold;
    color: #525bc4;
    cursor: pointer;
}

.transaction_type_selector {
    position: relative;
    left: 25px;
    bottom: 10px;
    padding: 5px 5px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid #525bc4;
    background-color: #525bc4;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transaction_type_selector:hover {
    background-color: #525bc4; 
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.transaction_type_selector:focus {
    outline: none;
    box-shadow: 0 0 8px #525bc4; 
}

.submitButton_delete{
    background-color: #c52e2e; 
    color: white;
    font-weight: bold;
    padding: 12px 20px; 
    border: initial; 
    border-radius: 4px; 
    cursor: pointer; 
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.submitButton_delete:active   {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.closeModalButton {
    background-color: #8a8686; 
    color: white;
    font-weight: bold;
    padding: 12px 20px; 
    border: initial; 
    border-radius: 4px; 
    cursor: pointer; 
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.closeModalButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 230px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.model_delete_product_h1 {
    text-align: center;
    padding: 15px 20px;
    border: 2px solid #b0b0b0;
    background-color: #b0b0b0;
    border-radius: 8px;
}

.model_delete_product_text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

input[type="datetime-local"] {
    width: 100%;
    padding: 10px;
    margin-bottom: 10px;
    border: 1px solid #ccc; /* Цвет границы */
    border-radius: 4px; /* Закругление углов */
    font-size: 16px; /* Размер шрифта */
}


.dateFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.dateFilterContainer label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.dateFilterContainer input[type="date"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 100%;
}

.dateFilterContainer input[type="date"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    outline: none;
}

.applyButton {
    background-color: #525bc4; 
    color: white;
    padding: 12px 20px; 
    border: initial; 
    border-radius: 4px; 
    cursor: pointer; 
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;  
}

.applyButton:active   {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.transaction_showcase_names_name {
    text-align: center;
    margin-bottom: 10px;
    font-size: 20px;
    color: #333;
}

.calculateContainer {
    /* min-width: 1045px; */
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
    width: 100%;
}

.calculateData {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 63%;
}

.calculate {
    width: 30%;
    margin-left: 50px;
}

.space {
    margin-left: 10px;
}