.main_div_user{
    width: 90%;
    display: grid;
    align-items: center;
    grid-template-columns: 80% 20%;
    margin: 20px auto;
}
.detailContainer {
    display: grid;
    grid-template-columns: 1fr;
    width: 90%;
    margin: 30px auto 0  auto;
    gap: 40px;
}
.cont_btns {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
}
.updateProductForm {
    display: grid;
    grid-template-columns: 20% 25% 25% 28%;
    align-items: center;
    gap: 16px;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 25px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 1000px;
    height: 110px;
}

.formGroup_name label {
    display: block;
    font-size: 15;
    font-weight: bold;
    color: #333;

}

.formGroup_name input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
    font-size: 16px;

}

.formGroup_name input:focus {
    border-color: #525bc4;
    outline: none;
}


.formGroup_number {

}

.formGroup_number label {
    display: block;
    font-weight: bold;
    font-size: 15px;
    color: #333;

}

.formGroup_number input {
    width: 100%;
    padding: 5px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s;
    font-size: 16px;

}

.formGroup_number input:focus {
    border-color: #525bc4;
    outline: none;
}

.submitButton {
    background-color: #525bc4;
    color: white;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    width: 110px;
    height: 40px;
}

.submitButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.transaction_showcase_names_border {
    min-width: 778px;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
}

.debts_showcase_names {
    display: flex;
    justify-content: space-between;
    padding: 8px;
}

.debts_showcase_names_border {
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
}

.transaction_showcase_names {
    display: flex;
    justify-content: space-between;
}

.transactionsContainer {
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 25px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    overflow: scroll;
    height: 685px;
}

.debtsContainer {
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 25px;
    background-color: #f9f9f9;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    overflow: scroll;
    height: 600px;
}

.transaction_showcase_apiData {
    display: flex;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid #e7ded7;
    border-radius: 5px;
    margin-top: 10px;
    background-color: #ffffff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s;
}

.transaction_showcase_apiData:hover {
    transform: scale(1.02);
}

.transaction_showcase_names_name {
    text-align: center; 
    margin-bottom: 10px;
    font-size: 20px;
    color: #333; 
}

.debt_showcase_names_name {
    text-align: center; /* Центрируем заголовок */
    margin-bottom: 10px; /* Отступ снизу заголовка */
    font-size: 20px; /* Размер шрифта заголовка */
    color: #333; /* Цвет текста заголовка */
}
.transaction_name,
.transaction_person_name,
.transaction_quantity,
.transaction_price,
.transaction_amount {
    padding: 5px;
    text-align: center;
}

.debt_data,
.debt_,
.debt_amount {
    padding: 5px;
    text-align: center;
}

.transaction_name {
    width: 140px;
}

.debt_data {
    width: 140px;
}
.debt_method_display {
    width: 140px;
}
.debt_amount {
    width: 140px;
}

.transaction_person_name {
    width: 159px;
}

.transaction_method_display {
    width: 140px;
}

.transaction_quantity {
    width: 128px;
}

.transaction_price {
    width: 109px;
}

.transaction_amount {
    width: 85px;
}

.transactions_border {
    margin-top: 10px;
}

.transaction_thename_data {
    font-weight: bold;
    color: #525bc4;
    cursor: pointer;
    
}



.debt_thename_data {
    font-weight: bold;
    color: #525bc4;
    cursor: pointer;
}

.debt_thename_type {
    font-weight: bold;
}

.debt_thename_amount {
    font-weight: bold;
}

.transaction_type_selector {

    padding: 5px 5px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid #525bc4;
    background-color: #525bc4;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transaction_type_selector:hover {
    background-color: #525bc4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.transaction_type_selector:focus {
    outline: none;
    box-shadow: 0 0 8px #525bc4;
}

.submitButton_delete {
    background-color: #c52e2e;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.submitButton_delete:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.submitButton_delete_update {
    background-color: #c52e2e;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
    height: 40px;

}

.submitButton_delete_update:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}


.closeModalButton {
    background-color: #8a8686;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-left: 10px;
}

.closeModalButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 230px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.model_delete_product_h1 {
    text-align: center;
    padding: 15px 20px;
    border: 2px solid #b0b0b0;
    background-color: #b0b0b0;
    border-radius: 8px;
}

.model_delete_product_text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.dateFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.dateFilterContainer label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.dateFilterContainer input[type="date"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 100%;
}

.dateFilterContainer input[type="date"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    outline: none;
}

.applyButton {
    background-color: #525bc4;
    color: white;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.applyButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.button_add_debt {
    background-color: #c52e2e;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
}

.button_add_debt:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.button_refund_debt {
    background-color: #525bc4;
    color: white;
    font-weight: bold;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    height: 40px;
}

.button_refund_debt:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.btns {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    height: 80px;
    width: 60%;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 60px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.formGroup {
}

.create_product_label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.create_product_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
    font-size: 16px; 
}

.create_product_input::placeholder {
    color: #999; 
}

.create_product_input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.create_product_input:hover {
    border-color: #007bff;
}

.submitButton_create_debt {
    background-color: #525bc4;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-top: 15px;
}

.submitButton_create_debt:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.closeModalButton_debt {
    background-color: #dc3545;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #dc354580;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    font-weight: bold;

}

.closeModalButton_debt:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #dc354580;;
}

.title_product_create_form {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.transaction_type_selector_block {
    padding: 10px 20px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid #525bc4;
    background-color: #525bc4;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.transaction_type_selector_block:hover {
    background-color: #525bc4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.transaction_type_selector_block:focus {
    outline: none;
    box-shadow: 0 0 8px #525bc4;
}
.btnSelection{
    display: flex;
    justify-content: flex-end;
}
@media screen and (max-width: 1400px) {
    .updateProductForm {
        width: 100%;
    }
}
@media screen and (max-width: 1220px) {
    .updateProductForm h2 {
        font-size: 30px;
    }
    .updateProductForm {
        grid-template-columns: 30% 33% 33%;
        height: 150px;
        padding: 25px 10px 25px 25px;
    }
    .cont_btns {
        justify-content: start;
        margin: 0;
    }
}