.header {
    background-color: #525bc4;
    height: 65px;
    position: relative;
    z-index: 9999;
}

.header_block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
    width: 75%;
    height: 55px;
    font-weight: bold;
}

.header_navBlock {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 55%;
    transition: all 0.3s ease-in-out;
}

.burger {
    display: none;
    font-size: 24px;
    background: none;
    border: none;
    color: white;
    cursor: pointer;
}

.header_navBlock_non {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 15%;
}

.header_nav {
    color: aliceblue;
    font-size: 17px;
    text-decoration: none;
    font-family: Arial, Helvetica, sans-serif;
}

.header_a {
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: transparent;
    border: none;
    color: white;
    font-weight: bold;
}

.header_is_admin {
    font-size: 17px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: transparent;
    border: none;
    color: white;
    font-weight: bold;
    margin-left: 1480px;
}

@media screen and (max-width: 1200px) {
    .header_block {
        width: 85%;
    }
    .header_navBlock {
        width: 60%;
    }
    .header_navBlock_non {
        width: 60%;
    }
}

@media screen and (max-width: 1050px) {
    .header_nav {
        font-size: 16px;
    }
}

@media screen and (max-width: 900px) {
    .burger {
        display: block;
    }
    .header_navBlock {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #525bc4;
        position: absolute;
        top: 65px;
        left: 0;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        padding: 0;
        z-index: 9999;
        transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
    }
    .header_navBlock.open {
        max-height: 300px;
        padding: 0px 0px 10px 67px;
    }
    .header_navBlock_non {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        background-color: #525bc4;
        position: absolute;
        top: 65px;
        left: 0;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        padding: 0;
        z-index: 9999;
        transition: max-height 0.4s ease-in-out, padding 0.4s ease-in-out;
    }
    .header_navBlock_non.open {
        max-height: 300px;
        padding: 0px 0px 10px 67px;
    }
    .header_nav {
        margin: 10px 0;
        opacity: 0;
        transition: opacity 0.4s ease-in-out; 
    }
    .header_navBlock.open .header_nav {
        opacity: 1;
    }
    .header_navBlock_non.open .header_nav {
        opacity: 1;
    }
    .burgerMenu {
        margin: 12px 0 0 0;
        font-size: 28px;
    }
    .closeIcon {
        margin: 12px 0 0 0;
        font-size: 28px;
    }
}