.product_block {
    display: flex;
    justify-content: center;
    width: 100%;
}
.product_container {
    width: 90%;
}

.products_showcase_names {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: auto;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.product_title {
    text-align: center;
    margin-top: 20px;
    font-size: 35px;
}

.products_showcase_apiData {
    display: flex;
    margin-top: 10px;
    border: 1px solid #e7ded7;
    border-radius: 10px;
    padding: 20px 20px;
    overflow: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.products_showcase_apiData:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
                0 6px 20px rgba(82, 91, 196, 0.1);
}

.product_list {
    height: 500px;
    overflow: auto;
}

.products_name {
    width: 400px;
}

.products_quantity {
    width: 400px;
}

.products_min {
    width: 400px;
}

.products_purchase_price {
    width: 100px;
}

.products_showcase_data {
    width: 95%;
    margin: 20px auto;
    border: 2px solid #525bc4;
    border-radius: 8px;
    padding: 20px 40px;
}

.product_report {
    display: flex;
    justify-content: space-between;
    width: 50%;
    height: 40px;
}

.product_report_border {
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
    padding: 15px 15px;
}

.btn_product_create {
    height: 40px;
    width: 109px;
    background-color: #525bc4;
    border-radius: 5px;
    color: aliceblue;
    font-weight: bold;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.btn_product_create:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.total_data {
    margin-top: 12px;
}

.product_search {
    display: flex
}

.product_search {
    display: flex;
    justify-content: space-between;
    width: 50%;
    height: 35px;
    margin-top: 10px;
}

.search_input {
    width: 55%;
    padding: 10px;
    border: 2px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
    color: #333;
    transition: border-color 0.3s;
}

.search_input:focus {
    border-color: #8b7373;
    outline: none;
    box-shadow: 0 0 5px #525bc4;
}

.product_search_btn {
    height: 40px;
    width: 109px;
    background-color: #525bc4;
    font-weight: bold;
    border-radius: 5px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.product_search_btn:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.serach_label {
    margin-top: 9px;
    position: relative;
    right: 10px;
}

.search_checkbox {
    border-radius: 10px;
}

/* Modal window */
.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 60px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.formGroup {
    margin-bottom: 15px;
}

.create_product_label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.create_product_input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
    font-size: 16px; 
}

.create_product_input::placeholder {
    color: #999; 
}

.create_product_input:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.create_product_input:hover {
    border-color: #007bff;
}

.submitButton {
    background-color: #525bc4;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    margin-top: 15px;
}

.submitButton:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.closeModalButton {
    background-color: #dc3545;
    color: white;
    padding: 13px 50px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px #dc354580;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    position: relative;
    font-weight: bold;
    bottom: 40px;
    left: 170px;
}

.closeModalButton:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #dc354580;;
}

.title_product_create_form {
    text-align: center;
    margin-top: 10px;
    margin-bottom: 10px;
}

.barode_div {
    display: grid;
    grid-template-columns: 80% 19%;
    gap: 5px;
    text-align: center;
    justify-content: center;
}

.create_barcode_input {
    width: 23px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    margin-right: 40px;
    transition: border-color 0.3s ease, box-shadow 0.3s ease; 
}

.create_barcode_input::placeholder {
    color: #999; 
}

.int_cont {
    display: flex;
    justify-content: right;
}
@media screen and (max-width: 1450px) {
    .product_report {
        width: 60%;
    }
    .product_search {
        width: 60%;
    }
}
@media screen and (max-width: 1220px) {
    .product_container {
        width: 100%;
    }
    .product_report {
        width: 70%;
    }
    .product_search {
        width: 70%;
    }
}
@media screen and (max-width: 1000px) {
    .products_showcase_data {
        padding: 20px 20px;
    }
    .product_report {
        width: 80%;
    }
    .product_search {
        width: 80%;
    }
    .modalContent {
        margin-bottom: 0px;
        width: 580px;
    }
}
