.login {
    display: flex;
    justify-content: center;
    margin-top: 230px;
}

.login_block {
    border: 1px solid  #c9c9d3;
    border-radius: 20px;
    width: 950px;
    display: flex;
    justify-content: center;
    height: 550px;
    align-items: center;
    position: relative;
    bottom: 100px;
    box-shadow: 4px 6px 10px #e7988a66;
}

.login_form {
    width: 500px;
    height: 250px;
}

.login_input {
    margin-top: 5px;
}

.intLogin {
    height: 65px;
    width: 470px;
    border-radius: 9px;
    border: 1px solid #525bc4;
    background-color: transparent;
    color: #0d0e19;
    font-family: Montserrat,sans-serif;
    font-size: 1rem;
    font-weight: 200;
    padding: 0 0 0 20px;
    margin-bottom: 20px;
}

.intLogin:focus {
    outline: #525bc4;
    border-color: #525bc4;
}


.intPassword {
    height: 65px;
    width: 422px;
    border-radius: 9px;
    border: 1px solid #525bc4;
    background-color: transparent;
    color: #0d0e19;
    font-family: Montserrat,sans-serif;
    font-size: 1rem;
    font-weight: 200;
    padding: 0 0 0 20px;
    margin-bottom: 20px;
}

.intPassword:focus {
    outline: #525bc4;
    border-color: #525bc4;
}

.login__text {
    margin-left: 190px;
    font-size: 35px;
    font-family: Montserrat,sans-serif;;
    margin-bottom: 30px;
}

.loginBtn {
    margin-top: 20px;
    margin-left: 140px;
    padding: 15px 70px;
    background-color: #525bc4;
    border-radius: 3px;
    color: aliceblue;
    box-shadow: 4px 6px 10px #f16a5166;
    border: initial;
    transition: transform 0.1s ease, box-shadow 0.1s ease; 
}

.loginBtn:active {
    transform: scale(0.95);
    box-shadow: 2px 3px 5px #f16a5166;
}

.toggle_prices_btn {
    border: none;
    margin: 0 3px;
    padding: 10px;
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
}

.error {
    color: red;
    font-size: 14px;
    margin-top: 10px;
    background-color: #ffe6e6;
    border: 1px solid #ff9999;
    border-radius: 5px;
    padding: 10px;
    text-align: center;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

@media screen and (max-width: 1050px) {
    .login_block {
        width: 840px;
    }
}