.date_border {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 96.5%;
    padding: 10px 80px;
    margin-top: 10px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    border: 2px solid #ccc;
    border-radius: 8px;
    align-items: center;
}

.salePage {
    display: none;
}

.date_border_date {
    padding: 8px 12px;
    font-size: 16px;
    font-family: Arial, sans-serif;
    color: #333;
    border: 2px solid #ddd;
    border-radius: 8px;
    outline: none;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    cursor: pointer;
}

.date_border_date:hover {
    border-color: #b0b0b0;
}

.date_border_date:focus {
    border-color: #6a9efc;
    box-shadow: 0 0 5px rgba(106, 158, 252, 0.5);
}

.btns {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    width: 95%;
    padding: 13px 100px;
    margin-top: 10px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    border: 2px solid #ccc;
    border-radius: 8px;
}

.work_btn {
    text-decoration: none;
    color: #525bc4;
    padding: 9px 40px;
    border-radius: 3px;
    cursor: pointer;
    box-shadow: 4px 6px 10px rgba(241, 106, 81, 0.4);
    border: 2px solid #525bc4;
    font-weight: bold;
    font-size: 18px;
    transition: transform 0.2s ease, box-shadow 0.2s ease, background-color 0.2s ease, color 0.2s ease;
    width: 80%;
    opacity: 0.8;
    text-align: center;
    background-color: #ffffff;
    margin: 0 auto;
}

.work_btn:hover {
    background-color: #525bc4;
    color: #ffffff;
    opacity: 1;
    transform: scale(0.95);
}

.work_btn:active {
    transform: scale(0.9);
    box-shadow: 2px 3px 5px rgba(241, 106, 81, 0.4);
}

.container {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    margin: 20px auto;
    width: 100%;
    text-align: center;
    /* justify-content: center; */
    gap: 10px;
}

.block_result {
    margin: 0 auto;
    width: 96.5%;
    height: 600px;
    text-align: center;
    border: 2px solid #b8bbd7;
    border-radius: 8px;
    overflow: auto;
}

.category_select {
    position: relative;
    left: 25px;
    /* bottom: 10px; */
    padding: 8px 12px;
    font-size: 15px;
    font-weight: bold;
    border-radius: 8px;
    border: 2px solid #525bc4;
    background-color: #525bc4;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, box-shadow 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.category_select:hover {
    background-color: #525bc4;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.2);
}

.category_select:focus {
    outline: none;
    box-shadow: 0 0 8px #525bc4;
}

.container_products {
    display: flex;
    justify-content: space-between;
    width: 96.5%;
    /* height: 70px; */
    margin: 15px auto;
    border: 1px solid #e7ded7;
    border-radius: 10px;
    padding: 20px;
    overflow: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.container_products:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
        0 6px 20px rgba(82, 91, 196, 0.1);
}

.person_name {
    margin-right: 55px;
}



.container_result {
    width: 90%;
    margin: 0 auto;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
}

.container_result_sale {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 97%;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 8px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
}

.container_result_total_amount {
    display: flex;
    align-items: center;
    font-weight: bold;
    font-size: 17px;
}

.showprice {
    margin: 0 0 0 20px;
}

.showPrices {
    display: flex;
    justify-content: space-between;
    margin: 0 10px;
    min-width: 200px;
    line-height: 1.6;
}

.btn_cont_border {
    padding: 10px;
    margin: 0 auto;
    width: 90%;
    border: 2px solid #ccc;
    border-radius: 8px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
}

.btn_open_date {
    background-color: #525bc4;
    color: white;
    padding: 8px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    width: 250px;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
    height: 42px;
    margin-top: 3px;
}

.btn_open_date:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.modalOverlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modalContent {
    background-color: white;
    border-radius: 8px;
    padding: 30px;
    margin-bottom: 250px;
    width: 650px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    animation: fadeIn 0.3s ease;
}

.model_delete_product_h1 {
    text-align: center;
    padding: 15px 20px;
    border: 2px solid #b0b0b0;
    background-color: #b0b0b0;
    border-radius: 8px;
}

.model_delete_product_text {
    margin-top: 20px;
    margin-bottom: 20px;
    font-weight: bold;
}

.dateFilterContainer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin: 20px 0;
}

.dateFilterContainer label {
    font-size: 16px;
    font-weight: bold;
    color: #333;
    margin-bottom: 5px;
}

.dateFilterContainer input[type="date"] {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    transition: border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    width: 100%;
}

.dateFilterContainer input[type="date"]:focus {
    border-color: #007bff;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
    outline: none;
}

.applyButton {
    background-color: #525bc4;
    color: white;
    padding: 12px 20px;
    border: initial;
    border-radius: 4px;
    cursor: pointer;
    font-weight: bold;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.applyButton:active {
    transform: scale(1.05);
    box-shadow: 2px 3px 5px #f16a5166;
}

.container_product {
    width: 300px;
}

.container_product::-webkit-scrollbar {
    display: none;
}

.transaction_method {
    white-space: nowrap;
    margin-right: 10px;
    margin-left: 3px;
}

.other_container {
    margin: 20px 80px;
    display: grid;
    gap: 20px;
}

.close_button {
    margin-left: 82%;
}

.formSelect {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    appearance: none;
    background: #ffffff;
}

.formSelect:focus {
    border-color: #007bff;
    outline: none;
}

.cancelButton {
    background-color: #dc3545;
    color: white;
    border: none;
    padding: 10px 15px;
    border-radius: 4px;
    cursor: pointer;
}

.cancelButton:hover {
    background-color: #c82333;
}

.formGroup {
    margin-bottom: 15px;
    margin-top: 15px;
}

.formGroup label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
}

.formGroup input {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
}

.buttonGroup {
    display: flex;
    justify-content: space-between;
    width: 42%;
}

.formGroup select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    background-color: #fff;
    appearance: none;
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 10px;
}

.formGroup select:focus {
    border-color: #007BFF;
    outline: none;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.formGroup select option {
    padding: 10px;
}

.formGroup input:hover {
    border-color: #007BFF;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

.formGroup input:active {
    border-color: #0056b3;
    box-shadow: 0 0 5px rgba(0, 86, 179, 0.5);
}

.formGroup input:focus {
    outline: none;
    border-color: #007BFF;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
}

/* dailyReport */

.names {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    min-width: 1340px;
    height: 50px;
    padding: 15px 30px;
    border: 2px solid #ccc;
    border-radius: 12px;
    background: linear-gradient(135deg, #e0e0e0, #b0b0b0);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    font-family: 'Arial', sans-serif;
    font-weight: bold;
    color: #333;
    margin-top: 10px;
}

.api_border {
    margin: 10px auto;
    border: 1px solid #e7ded7;
    border-radius: 10px;
    padding: 20px 20px;
    overflow: auto;
    transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;

}

.api_border:hover {
    background-color: #e0e0e0;
    box-shadow: 0 4px 8px rgba(82, 91, 196, 0.4),
        0 6px 20px rgba(82, 91, 196, 0.1);
}

.api {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 auto;
}

.border_container {
    /* display: none; */
    margin-top: 20px;
    border: 2px solid #ccc;
    border-radius: 8px;
    padding: 10px 10px;
    width: 97%;
    margin-left: 29px;
}

.fields_names {
    width: 100px;
}

.buttons {
    padding: 10px 10px;
    background-color: #525bc4;
    color: #fff;
    border-radius: 10px;
    transition: transform 0.1s ease, box-shadow 0.1s ease;
}

.buttons:active {
    transform: scale(1.1);
}

.toggle_prices_btn {
    border: none;
    margin: 0 40px;
    padding: 10px;
    font-size: 20px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    /* background: transparent; */
}

@media screen and (max-width: 1400px) {
    .names {
        min-width: auto;
    }

    .border_container {
        width: 97%;
        overflow: auto;
    }

    .main_date_border {
        width: 1400px;
        /* Ограничение ширины */
        overflow-y: auto;
        /* Включить прокрутку, если текст выходит за пределы */
        scrollbar-width: none;
        /* Скрыть скроллбар в Firefox */
        white-space: nowrap;
    }
}

@media screen and (max-width: 1100px) {
    .border_container {
        margin: 20px 0 20px 18px;
    }

    .toggle_prices_btn {
        margin: 0px 20px;
    }

    .container_product {
        max-width: 300px;
        /* Ограничение ширины */
        max-height: 40px;
        /* Ограничение высоты */
        overflow-y: auto;
        /* Включить прокрутку, если текст выходит за пределы */
        scrollbar-width: none;
        /* Скрыть скроллбар в Firefox */
        white-space: nowrap;
    }

    .container_product::-webkit-scrollbar {
        display: none;
        /* Скрыть скроллбар в Chrome, Safari и Edge */
    }
}

@media screen and (max-width: 900px) {
    .h1_date_border {
        font-size: 25px;
    }

    .date_border {
        padding: 10px 30px;
    }
}

@media screen and (max-width: 600px) {
    .cont_border {
        display: grid;
        justify-content: space-around;
        gap: 10px;
    }

    .category_select {
        left: 0;
    }

    .container {
        grid-template-columns: 1fr;
    }

    .btn_cont_border {
        width: 97%;
    }

    .container_result_sale {
        padding: 15px 10px;
        display: grid;
        justify-content: stretch;
    }

    .btn_open_date {
        width: 90%;
        margin: 15px auto 0 auto;
    }

    .toggle_prices_btn {
        width: 40px;
        height: 40px;
    }

    .container_result_total_amount {
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: bold;
        font-size: 17px;
        width: 100%;
    }
    .border_container {
        margin: 20px 0 40px 10px;
    }
    .container_products {
        width: 500px;
    }
}